function openNav() {
    document.querySelector(".wp-site-blocks").classList.add("ojafy-sidenav-open");
	document.getElementById("ojafy-overlay").style.display = "block";
}

document.addEventListener('DOMContentLoaded', (event) => {
    var hamburger = document.querySelector('.hamburger');
    if (hamburger) {
        hamburger.addEventListener('click', openNav);
    }
});

function closeNav() {
    document.querySelector(".wp-site-blocks").classList.remove("ojafy-sidenav-open");
	document.getElementById("ojafy-overlay").style.display = "none";
}

document.addEventListener('DOMContentLoaded', (event) => {
    var sidenav = document.querySelector('.closebtn');
    if (sidenav) {
        sidenav.addEventListener('click', closeNav);
    }
});

document.addEventListener('DOMContentLoaded', (event) => {
    var overlay = document.getElementById("ojafy-overlay");
    if (overlay) {
        overlay.addEventListener('click', closeNav);
    }
});

function toggleTheme() {
	const currentTheme = document.body.style.backgroundColor;
	if (currentTheme === "white" || currentTheme === "") {
		document.body.style.backgroundColor = "black";
		document.body.style.color = "white";
	} else {
		document.body.style.backgroundColor = "white";
		document.body.style.color = "black";
	}
}

document.addEventListener("DOMContentLoaded", function () {
	let menuItems = document.querySelectorAll(".nav-menu-mobile > li");

	menuItems.forEach((item) => {
		let clickableArea = item.querySelector(".menu-item-container-mobile");

		if (clickableArea) {
			clickableArea.addEventListener("click", function () {
				let subMenu = item.querySelector(".sub-menu");

				// Close other submenus
				menuItems.forEach((innerItem) => {
					let innerSubMenu = innerItem.querySelector(".sub-menu");
					if (innerSubMenu && innerSubMenu !== subMenu) {
						innerSubMenu.style.display = "none";
					}
				});

				if (subMenu) {
					subMenu.style.display =
						subMenu.style.display === "none" || !subMenu.style.display
							? "block"
							: "none";
				}
			});
		}
	});
});
